import React, { useEffect, useRef } from 'react'
import { globalHistory } from "@reach/router"
import { graphql } from 'gatsby'
// import SEO from '../components/SEO/SEO'
// import ArticleContainer from '../containers/ArticleContainer'
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import Layout from '../containers/Layout'
import { initParticipantCategory, setInitalizedToTrue, setIsMapView, setIsCategoryView, updateRoute, setActiveParticipant, activateParticipant, setCurrentParticipants, setActivatedParticipantOffsetX } from '../state/actions'
import store from "../state/createStore"
import { mediaQueryTracker} from 'redux-mediaquery'

const PageTemplate = (props) => {
  const { data, pageContext, isStateInitialized, 
    isMapView,
    initParticipantCategory, setInitalizedToTrue,
    setIsMapView, setIsCategoryView, updateRoute, setActiveParticipant, activateParticipant, participants, setCurrentParticipants, setActivatedParticipantOffsetX  } = props;

  const { categoryDatabaseId : id, name } = pageContext;
  // console.log('\n\n\n>>>>>>participantCategoryPage pageContext: ', pageContext );
  // const boundHydratePlaces = bindActionCreators(actions, dispatch)
  // console.log('boundHydratePlaces: ', boundHydratePlaces)
  // const action = boundHydratePlaces.filterParticipants()
  // dispatch(action)


  // console.log('DDDD AAA TTAAA: ', data);
  // const {
  //   data: {
  //     wpParticipantCategory: { content, cleanTitle },
  //   },
  // } = props
  const {
    allWpParticipant: { nodes },
  } = data

  
  const ps = nodes.map(p => ({
    id: p.databaseId,
    categoryId: p.participantCategories.nodes[0].databaseId
  }))

  const categoryNodes = nodes.filter(n => n.participantCategories.nodes[0].databaseId === id)

  // console.log('categoryNodes: ', categoryNodes)
  // console.log('ps: ', ps, 'id: ', id);

  const firstParticipantId = categoryNodes[0].databaseId;


  const mounted = useRef();

  useEffect(() => {
    if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true;
      console.log('participantCategoryPage mounted')
      // }))
      // mediaQueryTracker({
      //   debounceTime: 15000,
      //   queries: [
      //     {isPhone : "screen and (max-width: 767px)"},
      //     {isTablet: "screen and (min-width: 768px) and (max-width: 1024px)"},
      //     {isDesktop: "screen and (min-width: 1025px)"}
      //   ],
      //   windowDimensions: {
      //     doTrack: true,
      //     innerHeight: 0,
      //     innerWidth: 0
      //   }
      // }, store.dispatch );


      // store.dispatch(mediaQueryTracker({
      //   isPhone: "screen and (max-width: 767px)",
      //   innerWidth: true,
      //   innerHeight: true,
      // }))
      // console.log('participantCategories.nodes[0]', participantCategories.nodes[0])
      // if(!lastCategoryRoute){
      //   filterParticipants(categoryDatabaseId, categoryName, categoryUri)
      // }
      // if(participants.length === 0 ){
      //   setCurrentParticipants(ps)
      // }
      setIsCategoryView(true);
      if(participants.length === 0 ){
        setCurrentParticipants(ps)
        setActivatedParticipantOffsetX(24)
        setActiveParticipant(firstParticipantId)
        activateParticipant(firstParticipantId)
      }
    
    } else {
      console.log('participantCategoryPage updated')
      // do componentDidUpdate logic
      // console.log('isMapView: ', isMapView, 'isCategoryView: ', isCategoryView, ', activated_participant_id: ', activated_participant_id)
      // if(!lastCategoryRoute){
      //   filterParticipants(categoryDatabaseId, categoryName, categoryUri)
      // }
      if(participants.length === 0 ){
        setCurrentParticipants(ps)
        setActivatedParticipantOffsetX(24)
        setActiveParticipant(firstParticipantId)
        activateParticipant(firstParticipantId)
      }
    
      // if(isMapView && !isCategoryView && !activated_participant_id) {
      //   console.log('isMapView && !isCategoryView && !activated_participant_id')
      //   // setActivatedParticipantOffsetX(e.currentTarget.offsetLeft);
      //   setActiveParticipant(databaseId)
      //   activateParticipant(databaseId)
      // } else if(activated_participant_id) {
      //   const el = document.getElementById(`mi_card_mobile_${databaseId}`);
      //   if(el){
      //     const offsetX = el.offsetLeft;
      //     console.log('offsetX: ', offsetX);
      //     setActivatedParticipantOffsetX(offsetX)
      //   }



      // }
    
    }
  });
  
  // console.log('firstParticipantId: ', firstParticipantId)

  // const facebookImage =
  //       featuredImage?.node?.remoteFile?.childImageSharp?.facebook?.src || false

  // const twitterImage =
  //       featuredImage?.node?.remoteFile?.childImageSharp?.twitter?.src || false

  // will be `false` during SSR and `true` in the browser
  // const isStateInitialized = useSelector(state => state.isInitialized);
  if (!isStateInitialized) {

    // const cat = {
    //   id: catagoryDatabaseId,
    //   name
    // }
    // console.log('initStateFromQuery id: ', id, ' name: ', name)
    initParticipantCategory(id, name)
    setInitalizedToTrue();
    setIsMapView(true);
    setIsCategoryView(true);

    setCurrentParticipants(ps)
    setActivatedParticipantOffsetX(24)
    setActiveParticipant(firstParticipantId)
    activateParticipant(firstParticipantId)

    updateRoute(globalHistory.location)
    // also set state.isInitialized to true in your reducer
    // dispatch({ type: 'INITIALIZE_STATE_FROM_QUERY', payload: data })
  } else if(participants.length === 0 ){
    setCurrentParticipants(ps)
    setActivatedParticipantOffsetX(24)
    setActiveParticipant(firstParticipantId)
    activateParticipant(firstParticipantId)
  }

  return (
    <Layout>
      {/* <h1>participant category page</h1> */}
      
      {
        categoryNodes.map(( node, count ) => {
          const { 
            latitude,
            longitude,
            streetName,
            streetNumber,
            placeId,
            city,
            postCode,
            streetAddress
          } = node.mi_participant.miParticipantGeodata;
          return (
            <div key={node.id}>
              {/* <div>{node.title}</div>
              <div>
                lat:
                {latitude}
              </div>
              <div>
                long:
                {longitude}
              </div> */}
            </div>
          )
        })
      }
    </Layout>
  )
}




// export const query = graphql`
//     query Posts($offset: Int!, $perPage: Int!) {
//         allWpPost(
//             limit: $perPage
//             skip: $offset
//             sort: { fields: date, order: DESC }
//         ) {
//             nodes {
//                 ...PostPreviewContent
//             }
//             pageInfo {
//                 currentPage
//                 pageCount
//             }
//         }
//     }
// `
// export const query = graphql`
//     query Participants($categoryDatabaseId: Int! = 5) {
//       allWpParticipant(filter: {participantCategories: {nodes: {elemMatch: {id: {}, databaseId: {eq: $categoryDatabaseId}}}}}) {
//         nodes {
//           databaseId
//           id
//           slug
//           title
//           uri
//           participantCategories {
//             nodes {
//               databaseId
//               name
//             }
//           }
//           mi_participant {
//             miParticipantShorttitle
//             miParticipantHeroSlider {
//               imageFile {
//                 childImageSharp {
//                     fluid(maxHeight: 162, maxWidth: 288, quality: 90, cropFocus: CENTER) {
//                         ...GatsbyImageSharpFluid_tracedSVG
//                     }
//                 }
//               }
//             }
//             miParticipantGeodata {
//               latitude
//               longitude
//               streetName
//               streetNumber
//               placeId
//               city
//               postCode
//               streetAddress
//             }
//           }
//         }
//       }
//     }
// `

export const query = graphql`
    query AllParticipantsCategory{
      allWpParticipant{
        nodes {
          databaseId
          id
          slug
          title
          uri
          participantCategories {
            nodes {
              databaseId
              name
            }
          }
          mi_participant {
            miParticipantShorttitle
            miParticipantHeroSlider {
              imageFile {
                childImageSharp {
                  fluid(
                    maxHeight: 540, 
                    maxWidth: 960, 
                    quality: 80, 
                    cropFocus: CENTER,
                  ) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
              }
            }
            miParticipantGeodata {
              latitude
              longitude
              streetName
              streetNumber
              placeId
              city
              postCode
              streetAddress
            }
          }
        }
      }
    }
`

const mapStateToProps = state => {
  // console.log('mapStateToProps participantCategoryPage: ', state)
  return {
    isStateInitialized: state.isStateInitialized,
    active_participant_id: state.participants.active_participant_id,
    activated_participant_id: state.participants.activated_participant_id,
    participants: state.participants.participants,
  }
};

const mapDispatchToProps = {
  initParticipantCategory,
  setInitalizedToTrue,
  setIsMapView,
  setIsCategoryView,
  updateRoute,
  setActiveParticipant,
  activateParticipant,
  setCurrentParticipants,
  setActivatedParticipantOffsetX,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTemplate)
